import axios from 'axios';
import { FETCH_DOCUMENTS_SUCCESS, FETCH_DOCUMENT, UNAUTH_USER, UPDATE_DOCUMENT, CREATE_DIAGNOSIS } from '../types';

import { headers } from '../defaults';
import { API_URL } from '../../environment';

export function fetchDocuments(parameters = '', callback = () => {}) {
  return dispatch => {
    dispatch({ type: 'FETCH_DOCUMENTS_REQUEST' });
    axios
      .get(`${API_URL}/practice/documents${parameters}`, headers())
      .then(response => {
        dispatch({
          type: FETCH_DOCUMENTS_SUCCESS,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        dispatch({ type: 'FETCH_DOCUMENTS_FAILURE' });
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchPaginatedDocuments(parameters, callback = () => {}) {
  return dispatch => {
    dispatch({ type: 'FETCH_PAGINATED_DOCUMENTS_REQUEST' });
    axios
      .get(`${API_URL}/practice/documents/paginated_index${parameters}`, headers())
      .then(response => {
        dispatch({ type: 'FETCH_PAGINATED_DOCUMENTS_SUCCESS' });
        callback(response.data, null);
      })
      .catch(error => {
        dispatch({ type: 'FETCH_PAGINATED_DOCUMENTS_FAILURE' });
        callback(null, error);
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchDocument(id, callback = () => {}, errorCallback = () => {}) {
  return dispatch => {
    axios
      .get(`${API_URL}/practice/documents/${id}`, headers())
      .then(response => {
        dispatch({
          type: FETCH_DOCUMENT,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        } else {
          errorCallback(error);
        }
      });
  };
}

export function updateDocument(id, values, callback = () => {}) {
  return dispatch => {
    axios
      .put(`${API_URL}/practice/documents/${id}`, values, headers())
      .then(response => {
        dispatch({
          type: UPDATE_DOCUMENT,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function createDiagnosis(values, callback = () => {}) {
  return dispatch => {
    axios
      .post(`${API_URL}/practice/diagnoses`, values, headers())
      .then(response => {
        dispatch({
          type: CREATE_DIAGNOSIS,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}
