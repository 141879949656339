import axios from 'axios';
import { headers } from '../defaults';
import {
  FETCH_STATES,
  FETCH_AGE_GROUPS,
  FETCH_DEGREES,
  FETCH_THERAPEUTIC_ORIENTATION,
  FETCH_DIAGNOSIS_TYPES,
  FETCH_SPECIALITIES,
  FETCH_GENDER_IDENTITIES,
  FETCH_RACES,
  FETCH_DESIGNATIONS,
  FETCH_DISORDERS,
  FETCH_LANGUAGES,
  FETCH_RELIGIONS,
  FETCH_PRONOUNS,
  FETCH_GENERIC_ROLES,
  EMAIL_ERROR,
  SET_CALENDAR_RANGE,
  FETCH_TWILIO_TOKEN,
  FETCH_GALLIVAN_SCHOOLS,
  CHECK_NEW_MESSAGE,
  SET_NEW_MESSAGE_HANDLER,
  FETCH_APPROACH_TO_CARES,
  FETCH_INSURANCE_COMPANIES,
  FETCH_TRAINING_PROGRAMS,
  URL_ERROR,
  SET_APP_LOCALE,
} from '../types';
import { API_URL } from '../../environment';
import populateSelects from '../populateSelects';

export const checkEmail = (
  email,
  callback = () => {},
  errorCallback = () => {},
) => dispatch => {
  axios
    .get(
      `${API_URL}/practice/users/check_email?email=${encodeURIComponent(
        email,
      )}`,
      headers(),
    )
    .then(response => {
      dispatch({
        type: EMAIL_ERROR,
        payload: null,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: EMAIL_ERROR, payload: error.response });
      errorCallback(error);
    });
};

export const checkUrl = (
  url,
  callback = () => {},
  errorCallback = () => {},
) => dispatch => {
  axios
    .get(
      `${API_URL}/practice/users/check_url?url=${encodeURIComponent(url)}`,
      headers(),
    )
    .then(response => {
      dispatch({
        type: URL_ERROR,
        payload: null,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: URL_ERROR, payload: error.response });
      errorCallback(error);
    });
};

export const fetchAgeGroups = () => dispatch => {
  populateSelects('age_groups', FETCH_AGE_GROUPS, dispatch);
};

export const fetchStates = isoCode => dispatch => {
  populateSelects(`v2/states?iso_code=${isoCode}`, FETCH_STATES, dispatch);
};

export const fetchDegrees = () => dispatch => {
  populateSelects('degrees', FETCH_DEGREES, dispatch);
};

export const fetchApproachToCares = () => dispatch => {
  populateSelects(
    'practice/approach_to_cares',
    FETCH_APPROACH_TO_CARES,
    dispatch,
  );
};

export const fetchTherapeuticOrientations = () => dispatch => {
  populateSelects(
    'therapeutic_orientations',
    FETCH_THERAPEUTIC_ORIENTATION,
    dispatch,
  );
};

export const fetchDiagnosisTypes = () => dispatch => {
  populateSelects('diagnosis_types', FETCH_DIAGNOSIS_TYPES, dispatch);
};

export const fetchSpecialities = () => dispatch => {
  populateSelects('specialities', FETCH_SPECIALITIES, dispatch);
};

export const fetchReligions = () => dispatch => {
  populateSelects('practice/religions', FETCH_RELIGIONS, dispatch);
};

export const fetchGenderIdentities = () => dispatch => {
  populateSelects('gender_identities', FETCH_GENDER_IDENTITIES, dispatch);
};

export const fetchRaces = () => dispatch => {
  populateSelects('races', FETCH_RACES, dispatch);
};

export const fetchDesignations = () => dispatch => {
  populateSelects('designations', FETCH_DESIGNATIONS, dispatch);
};

export const fetchTrainingPrograms = () => dispatch => {
  populateSelects('training_programs', FETCH_TRAINING_PROGRAMS, dispatch);
};

export const fetchDisorders = () => dispatch => {
  populateSelects('disorders', FETCH_DISORDERS, dispatch);
};

export const fetchLanguages = () => dispatch => {
  populateSelects('practice/languages', FETCH_LANGUAGES, dispatch);
};

export const fetchPronouns = () => dispatch => {
  populateSelects('pronouns', FETCH_PRONOUNS, dispatch);
};

export const fetchGenericRoles = (callback = () => {}) => dispatch => {
  axios.get(`${API_URL}/generic_roles/`, headers()).then(response => {
    dispatch({
      type: FETCH_GENERIC_ROLES,
      payload: response,
    });
    callback(response);
  });
};

export const fetchInsuranceCompanies = (callback = () => {}) => dispatch => {
  axios.get(`${API_URL}/v2/insurance_companies/`, headers()).then(response => {
    dispatch({
      type: FETCH_INSURANCE_COMPANIES,
      payload: response,
    });
    callback(response);
  });
};

export const fetchTwilioToken = (callback = () => {}) => dispatch => {
  axios.get(`${API_URL}/twilio_chat/getToken`, headers()).then(response => {
    dispatch({
      type: FETCH_TWILIO_TOKEN,
      payload: response,
    });
    callback(response);
  });
  return Promise.resolve();
};

export const setCalendarRange = (startDate, endDate) => dispatch => {
  dispatch({
    type: SET_CALENDAR_RANGE,
    payload: { startDate, endDate },
  });
  return Promise.resolve();
};

export const fetchGallivanSchools = (callback = () => {}) => dispatch => {
  axios
    .get(`${API_URL}/practice/gallivan_schools/`, headers())
    .then(response => {
      dispatch({
        type: FETCH_GALLIVAN_SCHOOLS,
        payload: response,
      });
      callback(response);
    });
};

// Breaks messaging if in messaging action/reducer so its in misc
export function checkNewMessage(callback = () => {}) {
  return dispatch => {
    axios
      .get(`${API_URL}/chats/check_new_message`, headers())
      .then(response => {
        dispatch({
          type: CHECK_NEW_MESSAGE,
          payload: response,
        });
        callback(response);
      })
      .catch(() => {
        // do nothing right now
      });
  };
}

export function setNewMessageState(hasNewMessages) {
  return dispatch => {
    dispatch({
      type: CHECK_NEW_MESSAGE,
      payload: { data: hasNewMessages },
    });
  };
}

export function setNewMessageHandler(handler) {
  return dispatch => {
    dispatch({
      type: SET_NEW_MESSAGE_HANDLER,
      payload: { data: handler },
    });
  };
}

export const sendWelcomeEmail = () => dispatch =>
  axios
    .get(`${API_URL}/practice/users/welcome_email`, headers())
    .then(response =>
      dispatch({ type: 'SEND_WELCOME_EMAIL', payload: response }),
    );

export function setAppLocale(locale) {
  return dispatch => {
    localStorage.setItem('locale', locale);
    dispatch({
      type: SET_APP_LOCALE,
      payload: { data: locale },
    });
  };
}
