/* eslint no-param-reassign: 0 */
import axios from 'axios';
import {
  FETCH_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_SESSIONS,
  FETCH_APPOINTMENT_NOTE,
  UPDATE_APPOINTMENT_NOTE,
  FETCH_APPOINTMENT_NOTES,
  UPDATE_ASSESSMENT_SCORE,
  FETCH_SESSION_STATS,
  UNAUTH_USER,
} from '../types';

import { headers } from '../defaults';
import { API_URL } from '../../environment';

export function fetchAppointment(id, callback = () => {}) {
  return function (dispatch) {
    dispatch({ type: 'FETCH_APPOINTMENT_REQUEST' });
    axios
      .get(`${API_URL}/appointments/${id}`, headers())
      .then(response => {
        dispatch({
          type: FETCH_APPOINTMENT_SUCCESS,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        dispatch({ type: 'FETCH_APPOINTMENT_FAILURE' });
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function updateAssessmentScore(values, callback = () => {}) {
  return function (dispatch) {
    axios
      .put(`${API_URL}/assessment_scores/updatedScores`, values, headers())
      .then(response => {
        dispatch({
          type: UPDATE_ASSESSMENT_SCORE,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function updateAppointment(id, values, callback = () => {}) {
  return dispatch => {
    axios
      .put(`${API_URL}/appointments/${id}`, values, headers())
      .then(response => {
        dispatch({
          type: UPDATE_APPOINTMENT,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function updateAppointmentSession(id, values, callback = () => {}) {
  return dispatch => {
    axios
      .put(`${API_URL}/appointment_sessions/${id}`, values, headers())
      .then(response => {
        dispatch({
          type: UPDATE_APPOINTMENT_SESSIONS,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchAppointmentNote(id, callback = () => {}) {
  return dispatch => {
    axios
      .get(`${API_URL}/appointment_notes/${id}`, headers())
      .then(response => {
        dispatch({
          type: FETCH_APPOINTMENT_NOTE,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function updateAppointmentNote(id, values, callback = () => {}) {
  return dispatch => {
    axios
      .put(`${API_URL}/appointment_notes/${id}`, values, headers())
      .then(response => {
        dispatch({
          type: UPDATE_APPOINTMENT_NOTE,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchAppointmentNotes(client_id, callback = () => {}) {
  return dispatch => {
    axios
      .get(`${API_URL}/appointment_notes?client_id=${client_id}`, headers())
      .then(response => {
        dispatch({
          type: FETCH_APPOINTMENT_NOTES,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchSessionAnalytics(client_id, callback = () => {}) {
  return dispatch => {
    axios
      .get(`${API_URL}/practice/analytics/sessions?client_id=${client_id}`, headers())
      .then(response => {
        dispatch({
          type: FETCH_SESSION_STATS,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchAlternateAudio(appointment_id, callback = () => {}, callbackError = () => {}) {
  return dispatch => {
    axios
      .get(`${API_URL}/conferences/number?appointment_id=${appointment_id}`, headers())
      .then(response => {
        dispatch({
          type: 'FETCH_TWILIO_CONFERENCE',
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
        callbackError(error.response.data);
      });
  };
}

export const completeConferenceNumber = (
  appointmentId,
  callback = () => {},
  errorCallback = () => {},
) => dispatch => {
  axios
    .put(`${API_URL}/conferences/complete?id=${appointmentId}`, headers())
    .then(response => {
      dispatch({
        type: 'COMPLETE_TWILIO_CONFERENCE',
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
      errorCallback(error.response.data);
    });
};

export const submitSessionFeedback = (values, callback = () => {}) => dispatch => {
  axios
    .put(`${API_URL}/v2/appointments/feedback`, values, headers())
    .then(response => {
      dispatch({
        type: 'SUBMIT_SESSION_FEEDBACK',
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};
