import axios from 'axios';
import {
  FETCH_USER_SUCCESS,
  UNAUTH_USER,
  UPDATE_USER_SUCCESS,
  CREATE_USER_SUCCESS,
  AUTH_USER,
} from '../types';
import { headers } from '../defaults';
import { API_URL } from '../../environment';
import { setUserId, setUserProperties } from '../../utils/amplitude';

const signIn = (response, dispatch) => {
  localStorage.setItem('token', response.data.access_token);
  localStorage.setItem(
    'name',
    `${response.data.first_name} ${response.data.last_name}`,
  );
  localStorage.setItem('email', response.data.email);
  localStorage.setItem('created_at', response.data.sign_up_date);
  localStorage.setItem('last_action_at', Date.now());
  dispatch({ type: AUTH_USER });
};

export function createUser(values) {
  return dispatch => {
    dispatch({ type: 'CREATE_USER_REQUEST' });
    return axios.post(`${API_URL}/practice/users`, values, headers()).then(
      response => {
        signIn(response, dispatch);
        return dispatch({
          type: CREATE_USER_SUCCESS,
          payload: response,
        });
      },
      error => {
        dispatch({ type: 'CREATE_USER_FAILURE' });
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      },
    );
  };
}

export function fetchUser(callback = () => {}, errorCallback = () => {}) {
  return dispatch => {
    // REQUEST FOR USER DATA
    dispatch({ type: 'FETCH_USER_REQUEST' });
    // Actual API CALL
    axios
      .get(`${API_URL}/practice/users/me`, headers())
      .then(response => {
        dispatch({
          type: FETCH_USER_SUCCESS,
          payload: response,
        });
        setUserId(response.data.id);
        setUserProperties(response.data.user_properties);
        callback(response);
      })
      .catch(error => {
        dispatch({ type: 'FETCH_USER_FAILURE' });
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
        errorCallback(error);
      });
  };
}

export function updateUser(values, callback = () => {}) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_USER_REQUEST' });
    return axios
      .put(`${API_URL}/practice/users/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: response,
        });
      });
  };
}

export function fetchUserSetting(callback = () => {}) {
  return function (dispatch) {
    axios
      .get(`${API_URL}/user_settings/me`, headers())
      .then(response => {
        dispatch({
          type: 'FETCH_USER_SETTING',
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}
export function updateUserSetting(values, callback = () => {}) {
  return function (dispatch) {
    axios
      .put(`${API_URL}/user_settings/me`, values, headers())
      .then(response => {
        dispatch({
          type: 'UPDATE_USER_SETTING',
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}
