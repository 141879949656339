// anything involving details about a CLIENT

import axios from 'axios';
import queryString from 'query-string';
import {
  CREATE_CLIENT,
  FETCH_CLIENTS_SUCCESS,
  UPDATE_CLIENT,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENT_MATCH_SUCCESS,
  UNAUTH_USER,
} from '../types';
import { headers } from '../defaults';
import { API_URL } from '../../environment';

export const fetchClient = (id, callback = () => {}) => dispatch => {
  dispatch({ type: 'FETCH_CLIENT_REQUEST' });
  axios
    .get(`${API_URL}/practice/clients/${id}`, headers())
    .then(response => {
      dispatch({
        type: FETCH_CLIENT_SUCCESS,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: 'FETCH_CLIENT_FAILURE' });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const fetchClientWithType = (
  id,
  providerType,
  callback = () => {},
) => dispatch => {
  let url = `${API_URL}/practice/clients/${id}`;
  if (providerType) {
    url = `${url}?provider_type=${providerType}`;
  }
  dispatch({ type: 'FETCH_CLIENT_REQUEST' });
  axios
    .get(url, headers())
    .then(response => {
      dispatch({
        type: FETCH_CLIENT_SUCCESS,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: 'FETCH_CLIENT_FAILURE' });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const fetchClients = (callback = () => {}) => dispatch => {
  dispatch({ type: 'FETCH_CLIENTS_REQUEST' });
  axios
    .get(`${API_URL}/practice/clients`, headers())
    .then(response => {
      dispatch({
        type: FETCH_CLIENTS_SUCCESS,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: 'FETCH_CLIENTS_FAILURE' });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const fetchSponsoredHours = (id, callback = () => {}) => dispatch => {
  dispatch({ type: 'FETCH_SPONSORED_HOURS_REQUEST' });
  axios
    .get(`${API_URL}/practice/clients/sponsored_hours?id=${id}`, headers())
    .then(response => {
      dispatch({
        type: 'FETCH_SPONSORED_HOURS_SUCCESS',
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: 'FETCH_SPONSORED_HOURS_FAILIURE' });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const fetchClientMatch = (id, callback = () => {}) => dispatch => {
  dispatch({ type: 'FETCH_CLIENT_MATCH_REQUEST' });
  axios
    .get(`${API_URL}/practice/match_answers/${id}`, headers())
    .then(response => {
      dispatch({
        type: FETCH_CLIENT_MATCH_SUCCESS,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: 'FETCH_CLIENT_MATCH_FAILURE' });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const fetchClientPaginatedIndex = (
  values,
  callback = () => {},
) => dispatch => {
  dispatch({ type: 'FETCH_CLIENT_PAGINATED_INDEX_REQUEST' });
  axios
    .get(
      `${API_URL}/practice/clients/paginated_index?${queryString.stringify(
        values,
      )}`,
      headers(),
    )
    .then(response => {
      dispatch({
        type: 'FETCH_CLIENT_PAGINATED_INDEX_SUCCESS',
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: 'FETCH_CLIENT_PAGINATED_INDEX_FAILURE' });
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const createClient = (
  values,
  callback = () => {},
  errorCallback = () => {},
) => dispatch => {
  axios
    .post(`${API_URL}/practice/clients/`, values, headers())
    .then(response => {
      dispatch({
        type: CREATE_CLIENT,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
      errorCallback(error);
    });
};

export const updateClient = (id, values, callback = () => {}) => dispatch => {
  axios
    .put(`${API_URL}/practice/clients/${id}`, values, headers())
    .then(response => {
      dispatch({
        type: UPDATE_CLIENT,
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: UNAUTH_USER });
      }
    });
};

export const fetchMatch = matchId =>
  axios.get(`${API_URL}/practice/matches/${matchId}`, headers());

export const updateMatch = (matchId, values) =>
  axios.put(`${API_URL}/practice/matches/${matchId}`, values, headers());
