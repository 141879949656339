import React from 'react';
import PropTypes from 'prop-types';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { LD_CLIENT_ID } from './environment';

function LDProvider(props) {
  return <div>{props.children}</div>;
}

LDProvider.propTypes = {
  children: PropTypes.object,
};

export default withLDProvider({
  clientSideID: LD_CLIENT_ID,
  user: {
    key: 'practice-anonymous',
    anonymous: true,
  },
})(LDProvider);
