import {
  OPEN_GLOBAL_SNACKBAR,
  CLOSE_GLOBAL_SNACKBAR,
} from '../types';

export function openGlobalSnackbar(message) {
  return dispatch => dispatch({
    type: OPEN_GLOBAL_SNACKBAR,
    payload: message,
  });
}

export function closeGlobalSnackbar() {
  return dispatch => dispatch({
    type: CLOSE_GLOBAL_SNACKBAR,
  });
}

