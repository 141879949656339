import axios from 'axios';
import { headers } from '../defaults';
import { API_URL } from '../../environment';
import { UNAUTH_USER } from '../types';

export function fetchChats(page = 1, query = '', callback = () => {}) {
  return dispatch => {
    dispatch({ type: 'FETCH_CHATS_REQUEST' });
    axios
      .get(`${API_URL}/practice/chats?query=${query}&page=${page}`, headers())
      .then(response => {
        dispatch({
          type: 'FETCH_CHATS_SUCCESS',
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        dispatch({ type: 'FETCH_CHATS_FAILURE' });
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchChat(id, callback = () => {}) {
  return dispatch => {
    dispatch({ type: 'FETCH_CHAT_REQUEST' });
    axios
      .get(`${API_URL}/practice/chats/${id}`, headers())
      .then(response => {
        dispatch({
          type: 'FETCH_CHAT_SUCCESS',
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function sendMessage(values, callback = () => {}) {
  return function (dispatch) {
    axios
      .post(`${API_URL}/practice/chats/message`, values, headers())
      .then(response => {
        dispatch({ type: 'CREATE_MESSAGE' });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function updateChat(id, values, callback = () => {}) {
  return function (dispatch) {
    axios
      .put(`${API_URL}/practice/chats/${id}`, values, headers())
      .then(response => {
        dispatch({ type: 'UPDATE_CHAT' });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchTwilioToken(callback = () => {}) {
  return dispatch => {
    dispatch({ type: 'FETCH_MESSAGE_TOKEN_REQUEST' });
    axios
      .get(`${API_URL}/practice/chats/token`, headers())
      .then(response => {
        dispatch({
          type: 'FETCH_MESSAGE_TOKEN_SUCCESS',
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        dispatch({ type: 'FETCH_MESSAGE_TOKEN_FAILURE' });
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchTwilioTokenWithChatId(chatId, callback = () => {}) {
  return dispatch => {
    dispatch({ type: 'FETCH_MESSAGE_TOKEN_REQUEST' });
    axios
      .get(`${API_URL}/practice/chats/token?chat_id=${chatId}`, headers())
      .then(response => {
        dispatch({
          type: 'FETCH_MESSAGE_TOKEN_SUCCESS',
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        dispatch({ type: 'FETCH_MESSAGE_TOKEN_FAILURE' });
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function markAsRead(values, callback = () => {}) {
  return dispatch => {
    dispatch({ type: 'MARK_AS_READ' });
    axios
      .put(`${API_URL}/practice/chats/mark_as_read`, values, headers())
      .then(response => {
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchMessages(id, callback = () => {}) {
  return dispatch => {
    dispatch({ type: 'FETCH_CHATS_REQUEST' });
    axios
      .get(`${API_URL}/practice/chats/messages?id=${id}`, headers())
      .then(response => {
        dispatch({
          type: 'FETCH_MESSAGES_SUCCESS',
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        dispatch({ type: 'FETCH_MESSAGES_FAILURE' });
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchCouplesGuestChatToken(appointment_id, callback = () => {}) {
  return dispatch => {
    axios
      .get(`${API_URL}/practice/chats/couples_guest_token?appointment_id=${appointment_id}`, headers())
      .then(response => {
        callback(response.data, null);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
        callback(null, error.response.data);
      });
  };
}
