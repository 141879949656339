/* eslint-disable func-names */
import axios from 'axios';
import { headers } from '../defaults';
import {
  UNAUTH_USER,
  UPDATE_PERSONAL_DETAILS_SUCESS,
  UPDATE_USA_DETAILS_SUCCESS,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_PROVIDER_DETAILS_SUCCESS,
  UPDATE_PREFERENCES_SUCCESS,
  UPDATE_PHONE_NUMBERS_SUCCESS,
  UPDATE_PROVIDER_BIO_SUCCESS,
  UPDATE_DISORDERS_SUCCESS,
  UPDATE_AGE_GROUPS_SUCCESS,
  UPDATE_LANGUAGES_SUCCESS,
  UPDATE_RELIGIONS,
  UPDATE_DEGREES_SUCCESS,
  UPDATE_DESIGNATIONS_SUCCESS,
  UPDATE_APPROACH_TO_CARES,
  UPDATE_PASSWORD,
  FETCH_GOVERNING_BODIES,
  UPDATE_GOVERNING_BODIES_SUCCESS,
  DELETE_GOVERNING_BODIES_SUCCESS,
  PASSWORD_ERROR,
  UPDATE_PRACTICE_DETAILS_SUCESS,
  UPDATE_ADDITIONAL_CERTIFICATIONS_SUCCESS,
  UPDATE_COUNSELLING_PREFERENCE_SUCCESS,
  UPDATE_GENERAL_AVAILABILITY_SUCCESS,
  UPLOAD_DOCUMENT_SUCCESS,
  FETCH_PRACTICE_PREFERENCES_REQUEST,
  FETCH_PRACTICE_PREFERENCES_SUCCESS,
  FETCH_PRACTICE_PREFERENCES_FAILURE,
} from '../types';
import { API_URL } from '../../environment';

export function updatePersonalDetails(values, callback = () => {}) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_PERSONAL_DETAILS_REQUEST' });
    return axios
      .put(`${API_URL}/practice/personal_details/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_PERSONAL_DETAILS_SUCESS,
          payload: response,
        });
      });
  };
}

export function updatePracticeDetails(values, callback = () => {}) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_PRACTICE_DETAILS_REQUEST' });
    return axios
      .put(`${API_URL}/practice/practice_details/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_PRACTICE_DETAILS_SUCESS,
          payload: response,
        });
      });
  };
}

export const fetchGoverningBodies = (callback = () => {}) => dispatch => {
  axios
    .get(`${API_URL}/practice/governing_bodies`, headers())
    .then(response => {
      dispatch({
        type: FETCH_GOVERNING_BODIES,
        payload: response,
      });
      callback(response);
    });
};

export const createGoverningBodies = (
  values,
  callback = () => {},
) => dispatch => {
  dispatch({ type: 'UPDATE_GOVERNING_BODIES_REQUEST' });
  return axios
    .post(`${API_URL}/practice/governing_bodies`, values, headers())
    .then(response => {
      callback(response);
      return dispatch({
        type: UPDATE_GOVERNING_BODIES_SUCCESS,
        payload: response,
      });
    });
};

export const deleteGoverningBodies = (
  values,
  callback = () => {},
) => dispatch => {
  dispatch({ type: 'DELETE_GOVERNING_BODIES_REQUEST' });
  return axios
    .delete(`${API_URL}/practice/governing_bodies/${values}`, headers())
    .then(response => {
      callback(response);
      return dispatch({
        type: DELETE_GOVERNING_BODIES_SUCCESS,
        payload: response,
      });
    });
};

export const updateSupervision = (
  values,
  callback = () => {},
  errorCallback = () => {},
) => dispatch => {
  dispatch({ type: 'UPDATE_SUPERVISION_REQUEST' });
  axios
    .put(`${API_URL}/practice/supervisions/me`, values, headers())
    .then(response => {
      dispatch({
        type: 'UPDATE_SUPERVISION_SUCCESS',
        payload: response,
      });
      callback(response);
    })
    .catch(error => {
      dispatch({ type: 'UPDATE_SUPERVISION_FAILURE' });
      errorCallback(error);
    });
};

export const fetchSupervision = (callback = () => {}) => dispatch => {
  axios.get(`${API_URL}/practice/supervisions/me`, headers()).then(response => {
    dispatch({
      type: 'FETCH_SUPERVISION',
      payload: response,
    });
    callback(response);
  });
};

export function updateAddress(values, callback = () => {}) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_ADDRESSES_REQUEST' });
    return axios
      .put(`${API_URL}/practice/addresses/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_ADDRESS_SUCCESS,
          payload: response,
        });
      });
  };
}

export function updateProviderDetails(values, callback = () => {}) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_PROVIDER_DETAILS_REQUEST' });
    return axios
      .put(`${API_URL}/practice/provider_details/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_PROVIDER_DETAILS_SUCCESS,
          payload: response,
        });
      });
  };
}

export function updateUsaDetails(values, callback = () => {}) {
  return function (dispatch) {
    return axios
      .put(`${API_URL}/practice/usa_provider_details/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_USA_DETAILS_SUCCESS,
          payload: response,
        });
      });
  };
}

export function updatePreferences(values, callback = () => {}) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_PREFERENCES_REQUEST' });
    return axios
      .put(`${API_URL}/practice/preferences/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_PREFERENCES_SUCCESS,
          payload: response,
        });
      });
  };
}

export function updatePhoneNumbers(values, callback = () => {}) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_PHONE_NUMBERS_REQUEST' });
    return axios
      .put(`${API_URL}/practice/phone_numbers/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_PHONE_NUMBERS_SUCCESS,
          payload: response,
        });
      });
  };
}

export function updateProviderBio(values, callback = () => {}) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_PROVIDER_BIO_REQUEST' });
    return axios
      .put(`${API_URL}/practice/provider_bios/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_PROVIDER_BIO_SUCCESS,
          payload: response,
        });
      });
  };
}

export function updateDisorders(values, callback = () => {}) {
  return function (dispatch) {
    return axios
      .put(`${API_URL}/practice/disorders/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_DISORDERS_SUCCESS,
          payload: response,
        });
      });
  };
}

export function updateAgeGroups(values, callback = () => {}) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_AGE_GROUPS_REQUEST' });
    return axios
      .put(`${API_URL}/practice/age_groups/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_AGE_GROUPS_SUCCESS,
          payload: response,
        });
      });
  };
}

export function updateLanguages(values, callback = () => {}) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_LANGUAGES_REQUEST' });
    return axios
      .put(`${API_URL}/practice/languages/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_LANGUAGES_SUCCESS,
          payload: response,
        });
      });
  };
}

export function updateReligions(values, callback = () => {}) {
  return function (dispatch) {
    axios
      .put(`${API_URL}/practice/religions/me`, values, headers())
      .then(response => {
        dispatch({
          type: UPDATE_RELIGIONS,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function updateDegrees(values, callback = () => {}) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_DEGREES_REQUEST' });
    return axios
      .put(`${API_URL}/practice/degrees/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_DEGREES_SUCCESS,
          payload: response,
        });
      });
  };
}

export function updateAdditionalCertifications(values, callback = () => {}) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_ADDITIONAL_CERTIFICATIONS_REQUEST' });
    return axios
      .put(
        `${API_URL}/practice/additional_certifications/me`,
        values,
        headers(),
      )
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_ADDITIONAL_CERTIFICATIONS_SUCCESS,
          payload: response,
        });
      });
  };
}

export function updateDesignations(values, callback = () => {}) {
  return function (dispatch) {
    return axios
      .put(`${API_URL}/practice/designations/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_DESIGNATIONS_SUCCESS,
          payload: response,
        });
      });
  };
}

export function updateApproachToCares(values, callback = () => {}) {
  return function (dispatch) {
    return axios
      .put(`${API_URL}/practice/approach_to_cares/me`, values, headers())
      .then(response => {
        callback(response);
        return dispatch({
          type: UPDATE_APPROACH_TO_CARES,
          payload: response,
        });
      });
  };
}

export function updatePassword(values, callback = () => {}) {
  return function (dispatch) {
    axios
      .put(`${API_URL}/passwords/me`, values, headers())
      .then(response => {
        dispatch({
          type: UPDATE_PASSWORD,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 422) {
          dispatch({ type: PASSWORD_ERROR, payload: error.response });
        }
        callback(null, error);
      });
  };
}

export function updateCounsellingPreference(values) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_COUNSELLING_PREFERENCE_REQUEST' });
    return axios
      .put(`${API_URL}/practice/counselling_preferences/me`, values, headers())
      .then(response =>
        dispatch({
          type: UPDATE_COUNSELLING_PREFERENCE_SUCCESS,
          payload: response,
        }));
  };
}

export function updateGeneralAvailability(values) {
  return function (dispatch) {
    dispatch({ type: 'UPDATE_GENERAL_AVAILABILITY_REQUEST' });
    return axios
      .put(`${API_URL}/practice/general_availabilities/me`, values, headers())
      .then(response =>
        dispatch({
          type: UPDATE_GENERAL_AVAILABILITY_SUCCESS,
          payload: response,
        }));
  };
}

export function uploadDocument(values) {
  return function (dispatch) {
    dispatch({ type: 'UPLOAD_DOCUMENT_REQUEST' });
    return axios
      .post(`${API_URL}/practice/counsellor_documents`, values, headers())
      .then(response =>
        dispatch({
          type: UPLOAD_DOCUMENT_SUCCESS,
          payload: response,
        }));
  };
}

export function fetchPracticePreferences(callback = () => {}) {
  return function(dispatch) {
    dispatch({ type: FETCH_PRACTICE_PREFERENCES_REQUEST });
    return axios
      .get(`${API_URL}/practice/practice_preferences/me`, headers())
      .then(response => {
        dispatch({
          type: FETCH_PRACTICE_PREFERENCES_SUCCESS,
          payload: response.data,
        });
        callback(response);
      })
      .catch(error => {
        dispatch({
          type: FETCH_PRACTICE_PREFERENCES_FAILURE,
          payload: error.response,
        });
        callback(null, error.response);
      });
  };
}

export function updatePracticePreferences(values, callback = () => {}) {
  return function() {
    return axios
      .put(`${API_URL}/practice/practice_preferences/me`, values, headers())
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(null, error.response);
      });
  };
}

export function createProviderOutOfOffice(values, callback = () => {}) {
  return function() {
    return axios
      .post(`${API_URL}/practice/provider_out_of_offices`, values, headers())
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(null, error.response);
      });
  };
}

export function cancelProviderOutOfOffice(callback = () => {}) {
  return function() {
    return axios
      .delete(`${API_URL}/practice/provider_out_of_offices/me`, headers())
      .then(response => {
        callback(response);
      })
      .catch(error => {
        callback(null, error.response);
      });
  };
}
