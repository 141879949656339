import axios from 'axios';
import { headers } from './defaults';
import { API_URL } from '../environment';

const populateSelects = (
  endPoint,
  dispatchType,
  dispatch,
  callback = () => {},
) => {
  axios.get(`${API_URL}/${endPoint}`, headers()).then(response => {
    dispatch({
      type: dispatchType,
      payload: response,
    });
  });
  // }
  callback();
};

export default populateSelects;
