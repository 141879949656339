import axios from 'axios';
import queryString from 'query-string';

import {
  FETCH_FILE_SHARES,
  CREATE_RESOURCE,
  UNAUTH_USER,
  FETCH_SHARED_WITH,
} from '../types';
import { headers } from '../defaults';
import { API_URL } from '../../environment';

export function fetchFileShares(clientId, callback = () => {}) {
  return function(dispatch) {
    axios
      .get(`${API_URL}/user_file_shares?user_id=${clientId}`, headers())
      .then(response => {
        dispatch({
          type: FETCH_FILE_SHARES,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchSharedWith(callback = () => {}) {
  return function(dispatch) {
    let resources = [];

    axios
      .get(`${API_URL}/user_file_shares/shared_with_all_clients`, headers())
      .then(response => {
        resources = response.data.map(file => ({
          ...file,
          id: `a_${file.id}`,
          toAllClients: true,
        }));
        return axios.get(`${API_URL}/user_file_shares/shared_with`, headers());
      })
      .then(response => {
        resources = [
          ...resources,
          ...response.data.map(file => ({ ...file, toAllClients: false })),
        ];
        dispatch({
          type: FETCH_SHARED_WITH,
          payload: resources,
        });
        callback(resources);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchSharedWithAll(callback = () => {}) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_SHARED_WITH_ALL_REQUEST' });
    axios
      .get(`${API_URL}/user_file_shares/shared_with_all_clients`, headers())
      .then(response => {
        const resources = response.data.map(file => ({
          ...file,
          id: `a_${file.id}`,
          toAllClients: true,
        }));
        dispatch({ type: 'FETCH_SHARED_WITH_ALL_SUCCESS' });
        callback(resources, null);
      })
      .catch(error => {
        dispatch({ type: 'FETCH_SHARED_WITH_ALL_FAILURE' });
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        } else {
          callback(null, error);
        }
      });
  };
}
export function fetchProviderFilesAboutClient(
  clientId,
  page,
  callback = () => {},
) {
  return function(dispatch) {
    axios
      .get(
        `${API_URL}/user_file_shares/provider_files_about_client?client_id=${clientId}&page=${page}`,
        headers(),
      )
      .then(response => {
        dispatch({
          type: 'FETCH_PROVIDER_FILES_ABOUT_CLIENT',
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function fetchInformedConsent(clientId, callback = () => {}) {
  return function(dispatch) {
    axios
      .get(
        `${API_URL}/user_file_shares/informed_consent?client_id=${clientId}`,
        headers(),
      )
      .then(response => {
        dispatch({
          type: 'FETCH_INFORMED_CONSENT',
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}
export function fetchPaginatedFileShares(
  page,
  clientId,
  sortBy,
  callback = () => {},
) {
  const params = {
    page,
    client_id: clientId,
    sort: sortBy,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PAGINATED_FILE_SHARES_REQUEST' });
    axios
      .get(
        `${API_URL}/user_file_shares/paginated_index?${queryString.stringify(
          params,
        )}`,
        headers(),
      )
      .then(response => {
        dispatch({ type: 'FETCH_PAGINATED_FILE_SHARES_SUCCESS' });
        callback(response.data, null);
      })
      .catch(error => {
        dispatch({ type: 'FETCH_PAGINATED_FILE_SHARES_FAILURE' });
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        } else {
          callback(null, error);
        }
      });
  };
}

export function fetchPaginatedSharedWith(
  page,
  clientId,
  sortBy,
  callback = () => {},
) {
  const params = {
    page,
    client_id: clientId,
    sort: sortBy,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PAGINATED_SHARED_WITH_REQUEST' });
    axios
      .get(
        `${API_URL}/user_file_shares/paginated_shared_with?${queryString.stringify(
          params,
        )}`,
        headers(),
      )
      .then(response => {
        dispatch({ type: 'FETCH_PAGINATED_SHARED_WITH_SUCCESS' });
        callback(response.data, null);
      })
      .catch(error => {
        dispatch({ type: 'FETCH_PAGINATED_SHARED_WITH_FAILURE' });
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        } else {
          callback(null, error);
        }
      });
  };
}

export function removeFileSharedToAll(resourceId, callback = () => {}) {
  return function(dispatch) {
    axios
      .put(
        `${API_URL}/user_file_shares/remove_shared_with_all_clients`,
        { id: resourceId.replace(/^a_/gm, '') },
        headers(),
      )
      .then(response => {
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function removeFile(id, callback = () => {}) {
  return function(dispatch) {
    axios
      .put(`${API_URL}/user_file_shares/remove_file`, { id }, headers())
      .then(response => callback(response))
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

// removes file that provider uploaded about client
export function removeProviderFileAboutClient(id, callback = () => {}) {
  return function(dispatch) {
    axios
      .put(
        `${API_URL}/user_file_shares/remove_provider_files_about_client`,
        { id },
        headers(),
      )
      .then(response => callback(response))
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function createResource(values, callback = () => {}) {
  return function(dispatch) {
    axios
      .post(`${API_URL}/resources`, values, headers())
      .then(response => {
        dispatch({
          type: CREATE_RESOURCE,
          payload: response,
        });
        callback(response);
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}
