import axios from 'axios';
import { headers } from '../defaults';
import {
  AUTH_USER,
  AUTH_ERROR,
  UNAUTH_USER,
  TWO_FACTOR,
  TWO_FACTOR_ERROR,
  RESET_PASSWORD_ERROR,
} from '../types';
import { API_URL } from '../../environment';

export function signinUser(
  values,
  callback = () => {},
  errorCallBack = () => {},
) {
  return dispatch => {
    axios
      .post(`${API_URL}/login`, values)
      .then(response => {
        if (response.data.two_factor_id) {
          dispatch({ type: TWO_FACTOR, payload: response.data });
        } else {
          localStorage.setItem('token', response.data.access_token);
          localStorage.setItem(
            'name',
            `${response.data.first_name} ${response.data.last_name}`,
          );
          localStorage.setItem('email', response.data.email);
          localStorage.setItem('created_at', response.data.sign_up_date);
          localStorage.setItem('last_action_at', Date.now());
          dispatch({ type: AUTH_USER });
        }
        callback(response);
      })
      .catch(error => {
        errorCallBack();
        dispatch({ type: AUTH_ERROR, payload: error.response.data.error });
      });
  };
}

export function cleanLocalStorage() {
  localStorage.removeItem('token');
  localStorage.removeItem('email');
  localStorage.removeItem('name');
  localStorage.removeItem('created_at');
  localStorage.removeItem('last_action_at');
}

export function signoutUser() {
  cleanLocalStorage();
  return { type: UNAUTH_USER };
}

export function forgotPassword(values, callback = () => {}) {
  return dispatch => {
    axios
      .post(`${API_URL}/v2/forgot_passwords`, values)
      .then(response => {
        callback(response.data.status);
      })
      .catch(error => {
        dispatch({
          type: RESET_PASSWORD_ERROR,
          payload: error.response.data.error,
        });
      });
  };
}

export function verifyTwoFactor(values, callback = () => {}) {
  return dispatch => {
    axios
      .put(`${API_URL}/practice/two_factor_auths/${values.id}`, values)
      .then(response => {
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem(
          'name',
          `${response.data.first_name} ${response.data.last_name}`,
        );
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('created_at', response.data.sign_up_date);
        dispatch({ type: AUTH_USER });
        callback(response);
      })
      .catch(error => {
        dispatch({
          type: TWO_FACTOR_ERROR,
          payload: error.response.data.error,
        });
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function getPin(values, callback = () => {}) {
  return dispatch => {
    axios
      .post(`${API_URL}/practice/two_factor_auths/pin`, values)
      .then(response => {
        dispatch({ type: TWO_FACTOR, payload: response.data });
        callback(response);
      })
      .catch(error => {
        dispatch({
          type: TWO_FACTOR_ERROR,
          payload: error.response.data.error,
        });
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: UNAUTH_USER });
        }
      });
  };
}

export function resetPassword(token, values, callback = () => {}) {
  return dispatch => {
    axios
      .put(
        `${API_URL}/v2/forgot_passwords/me?reset_password_token=${token}`,
        values,
      )
      .then(response => {
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem(
          'name',
          `${response.data.first_name} ${response.data.last_name}`,
        );
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('created_at', response.data.sign_up_date);
        dispatch({ type: AUTH_USER });
        callback(response.data);
      })
      .catch(error => {
        callback(null, error);
      });
  };
}

export const confirmUser = (
  values,
  callback = () => {},
  errorCallback = () => {},
) => dispatch => {
  axios
    .put(`${API_URL}/signup`, values)
    .then(response => {
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem(
        'name',
        `${response.data.first_name} ${response.data.last_name}`,
      );
      localStorage.setItem('email', response.data.email);
      localStorage.setItem('created_at', response.data.sign_up_date);
      localStorage.setItem('locale', response.data.locale);
      dispatch({ type: AUTH_USER });
      callback();
    })
    .catch(error => {
      dispatch({ type: AUTH_ERROR, payload: error.response });
      errorCallback(error);
    });
};

export const signinZendeskUser = (
  values,
  callback = () => {},
  errorCallBack = () => {},
) => dispatch => {
  axios
    .post(`${API_URL}/practice/zendesk_session`, values, headers())
    .then(response => {
      callback(response);
    })
    .catch(error => {
      dispatch({ type: AUTH_ERROR, payload: error.response.data.error });
      errorCallBack();
    });
};
