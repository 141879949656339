/* eslint-disable camelcase */
// DEFAULTS
export const UNAUTH_USER = 'UNAUTH_USER';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';

export const CREATE_AVAILABILITY = 'CREATE_AVAILABILITY';
export const UPDATE_AVAILABILITY = 'UPDATE_AVAILABILITY';
export const FETCH_AVAILABILITY_SUCCESS = 'FETCH_AVAILABILITY_SUCCESS';
export const FETCH_APPOINTMENT_TYPES = 'FETCH_APPOINTMENT_TYPES';
export const FETCH_AVAILABILITY_BY_ID = 'FETCH_AVAILABILITY_BY_ID';
export const DELETE_AVAILABILITY = 'DELETE_AVAILABILITY';
export const COMBINE_AVAILABILITY = 'COMBINE_AVAILABILITY';

export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const UPDATE_ASSESSMENT_SCORE = 'UPDATE_ASSESSMENT_SCORE';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const UPDATE_APPOINTMENT_SESSIONS = 'UPDATE_APPOINTMENT_SESSIONS';
export const FETCH_APPOINTMENT_NOTE = 'FETCH_APPOINTMENT_NOTE';
export const UPDATE_APPOINTMENT_NOTE = 'UPDATE_APPOINTMENT_NOTE';
export const FETCH_APPOINTMENT_NOTES = 'FETCH_APPOINTMENT_NOTES';
export const CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT';
export const UPDATE_USER_APPOINTMENT = 'UPDATE_USER_APPOINTMENT';
export const FETCH_USER_APPOINTMENT = 'FETCH_USER_APPOINTMENT';
export const FETCH_PENDING_APPOINTMENTS_SUCCESS =
  'FETCH_PENDING_APPOINTMENTS_SUCCESS';

export const FETCH_ALL_APPOINTMENTS_SUCCESS = 'FETCH_APPOINTMENTS_SUCCESS';
export const FETCH_TODAY_APPOINTMENTS_SUCCESS =
  'FETCH_TODAY_APPOINTMENTS_SUCCESS';
export const FETCH_PREVIOUS_APPOINTMENTS_SUCCESS =
  'FETCH_PREVIOUS_APPOINTMENTS_SUCCESS';
export const FETCH_UPCOMING_APPOINTMENTS_SUCCESS =
  'FETCH_UPCOMING_APPOINTMENTS_SUCCESS';
export const FETCH_CANCELED_APPOINTMENTS_SUCCESS =
  'FETCH_CANCELED_APPOINTMENTS_SUCCESS';
export const FETCH_COMPLETED_APPOINTMENTS_SUCCESS =
  'FETCH_COMPLETED_APPOINTMENTS_SUCCESS';
export const FETCH_IN_PROGRESS_APPOINTMENTS_SUCCESS =
  'FETCH_IN_PROGRESS_APPOINTMENTS_SUCCESS';
export const FETCH_GOVERNING_BODIES = 'FETCH_GOVERNING_BODIES';
export const UPDATE_GOVERNING_BODIES_SUCCESS =
  'UPDATE_GOVERNING_BODIES_SUCCESS';
export const DELETE_GOVERNING_BODIES_SUCCESS =
  'DELETE_GOVERNING_BODIES_SUCCESS';

export const CREATE_PROVIDER_SUCCESS = 'CREATE_PROVIDER_SUCCESS';

export const FETCH_CHATS_SUCCESS = 'FETCH_CHATS_SUCCESS';
export const FETCH_CHAT_SUCCESS = 'FETCH_CHAT_SUCCESS';
export const FETCH_MESSAGE_TOKEN_SUCCESS = 'FETCH_MESSAGE_TOKEN_SUCCESS';
export const UPDATE_CHAT_SUCCESS = 'UPDATE_CHAT_SUCCESS';
export const CHECK_NEW_MESSAGE = 'CHECK_NEW_MESSAGE';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const SET_NEW_MESSAGE_HANDLER = 'SET_NEW_MESSAGE_HANDLER';

// misc
export const FETCH_AGE_GROUPS = 'FETCH_AGE_GROUPS';
export const FETCH_DEGREES = 'FETCH_DEGREES';
export const FETCH_THERAPEUTIC_ORIENTATION = 'FETCH_THERAPEUTIC_ORIENTATION';
export const FETCH_DIAGNOSIS_TYPES = 'FETCH_DIAGNOSIS_TYPES';
export const FETCH_SPECIALITIES = 'FETCH_SPECIALITIES';
export const FETCH_GENDER_IDENTITIES = 'FETCH_GENDER_IDENTITIES';
export const FETCH_RACES = 'FETCH_RACES';
export const FETCH_DESIGNATIONS = 'FETCH_DESIGNATIONS';
export const FETCH_DISORDERS = 'FETCH_DISORDERS';
export const FETCH_SUPERVISION_TITLES = 'FETCH_SUPERVISION_TITLES';
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';
export const FETCH_RELIGIONS = 'FETCH_RELIGIONS';
export const FETCH_PRONOUNS = 'FETCH_PRONOUNS';
export const FETCH_GENERIC_ROLES = 'FETCH_GENERIC_ROLES';
export const FETCH_INSURANCE_COMPANIES = 'FETCH_INSURANCE_COMPANIES';
export const FETCH_GALLIVAN_SCHOOLS = 'FETCH_GALLIVAN_SCHOOLS';
export const SET_CALENDAR_RANGE = 'SET_CALENDAR_RANGE';
export const FETCH_TWILIO_TOKEN = 'FETCH_TWILIO_TOKEN';
export const FETCH_STATES = 'FETCH_STATES';
export const FETCH_APPROACH_TO_CARES = 'FETCH_APPROACH_TO_CARES';
export const FETCH_TRAINING_PROGRAMS = 'FETCH_TRAINING_PROGRAMS';

export const FETCH_FILE_SHARES = 'FETCH_FILE_SHARES';
export const CREATE_RESOURCE = 'CREATE_RESOURCE';
export const FETCH_SHARED_WITH = 'FETCH_SHARED_WITH';

export const FETCH_USER_SETTING = 'FETCH_USER_SETTING';
export const UPDATE_USER_SETTING = 'UPDATE_USER_SETTING';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const UPDATE_PERSONAL_DETAILS_SUCESS = 'UPDATE_PERSONAL_DETAILS_SUCESS';
export const UPDATE_PRACTICE_DETAILS_SUCESS = 'UPDATE_PRACTICE_DETAILS_SUCESS';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_PROVIDER_DETAILS_SUCCESS =
  'UPDATE_PROVIDER_DETAILS_SUCCESS';
export const UPDATE_PREFERENCES_SUCCESS = 'UPDATE_PREFERENCES_SUCCESS';
export const UPDATE_PHONE_NUMBERS_SUCCESS = 'UPDATE_PHONE_NUMBERS_SUCCESS';
export const UPDATE_PROVIDER_BIO_SUCCESS = 'UPDATE_PROVIDER_BIO_SUCCESS';
export const UPDATE_USA_DETAILS_SUCCESS = 'UPDATE_USA_DETAILS_SUCCESS';
export const UPDATE_DISORDERS_SUCCESS = 'UPDATE_DISORDERS_SUCCESS';
export const UPDATE_AGE_GROUPS_SUCCESS = 'UPDATE_AGE_GROUPS_SUCCESS';
export const UPDATE_LANGUAGES_SUCCESS = 'UPDATE_LANGUAGES_SUCCESS';
export const UPDATE_COUNSELLING_PREFERENCE_SUCCESS =
  'UPDATE_COUNSELLING_PREFERENCE_SUCCESS';
export const UPDATE_GENERAL_AVAILABILITY_SUCCESS =
  'UPDATE_GENERAL_AVAILABILITY_SUCCESS';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPDATE_RELIGIONS = 'UPDATE_RELIGIONS';
export const UPDATE_DEGREES_SUCCESS = 'UPDATE_DEGREES_SUCCESS';
export const UPDATE_ADDITIONAL_CERTIFICATIONS_SUCCESS =
  'UPDATE_ADDITIONAL_CERTIFICATIONS_SUCCESS';
export const UPDATE_DESIGNATIONS_SUCCESS = 'UPDATE_DESIGNATIONS_SUCCESS';
export const UPDATE_APPROACH_TO_CARES = 'UPDATE_APPROACH_TO_CARES';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const PASSWORD_ERROR = 'PASSWORD_ERROR';
export const PRACTICE_NAME_ERROR = 'PRACTICE_NAME_ERROR';

export const AUTH_USER = 'AUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR ';

export const FETCH_APPOINTMENT_SUCCESS = 'FETCH_APPOINTMENT_SUCCESS';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENT = 'FETCH_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';

// Session notes
export const CREATE_DIAGNOSIS = 'CREATE_DIAGNOSIS';

export const FETCH_DOCUMENT_PAGINATION_INFO = 'FETCH_DOCUMENT_PAGINATION_INFO';
export const FETCH_APPOINTMENT_PAGINATION_INFO =
  'FETCH_APPOINTMENT_PAGINATION_INFO';

// Client Match
export const FETCH_CLIENT_MATCH_SUCCESS = 'FETCH_CLIENT_MATCH_SUCCESS';
export const CREATE_CLIENT_MATCH = 'CREATE_CLIENT_MATCH';
export const UPDATE_CLIENT_MATCH = 'UPDATE_CLIENT_MATCH';

// Client insurance coverage
export const ADMIN_FETCH_CLIENT_INSURANCE_REQUEST =
  'ADMIN_FETCH_CLIENT_INSURANCE_REQUEST';
export const ADMIN_FETCH_CLIENT_INSURANCE_SUCCESS =
  'ADMIN_FETCH_CLIENT_INSURANCE_SUCCESS';
export const ADMIN_FETCH_CLIENT_INSURANCE_NOT_FOUND =
  'ADMIN_FETCH_CLIENT_INSURANCE_NOT_FOUND';
export const ADMIN_FETCH_CLIENT_INSURANCE_FAILURE =
  'ADMIN_FETCH_CLIENT_INSURANCE_FAILURE';

export const ADMIN_PUT_CLIENT_INSURANCE_REQUEST =
  'ADMIN_PUT_CLIENT_INSURANCE_REQUEST';
export const ADMIN_PUT_CLIENT_INSURANCE_SUCCESS =
  'ADMIN_PUT_CLIENT_INSURANCE_SUCCESS';
export const ADMIN_PUT_CLIENT_INSURANCE_FAILURE =
  'ADMIN_PUT_CLIENT_INSURANCE_FAILURE';

export const ADMIN_DELETE_CLIENT_INSURANCE_REQUEST =
  'ADMIN_DELETE_CLIENT_INSURANCE_REQUEST';
export const ADMIN_DELETE_CLIENT_INSURANCE_SUCCESS =
  'ADMIN_DELETE_CLIENT_INSURANCE_SUCCESS';
export const ADMIN_DELETE_CLIENT_INSURANCE_FAILURE =
  'ADMIN_DELETE_CLIENT_INSURANCE_FAILURE';

// admin
export const ADMIN_FETCH_PROVIDERS = 'ADMIN_FETCH_PROVIDERS';
export const ADMIN_FETCH_PROVIDER_REQUEST = 'ADMIN_FETCH_PROVIDER_REQUEST';
export const ADMIN_FETCH_PROVIDER_SUCCESS = 'ADMIN_FETCH_PROVIDER_SUCCESS';
export const ADMIN_FETCH_PROVIDER_FAILURE = 'ADMIN_FETCH_PROVIDER_FAILURE';
export const ADMIN_UPDATE_PROVIDER = 'ADMIN_FETCH_PROVIDER';

export const ADMIN_FETCH_CLIENT_REQUEST = 'ADMIN_FETCH_CLIENT_REQUEST';
export const ADMIN_FETCH_CLIENT_SUCCESS = 'ADMIN_FETCH_CLIENT_SUCCESS';
export const ADMIN_FETCH_CLIENT_FAILURE = 'ADMIN_FETCH_CLIENT_FAILURE';

export const ADMIN_FETCH_CLIENT_MATCH_REQUEST =
  'ADMIN_FETCH_CLIENT_MATCH_REQUEST';
export const ADMIN_FETCH_CLIENT_MATCH_SUCCESS =
  'ADMIN_FETCH_CLIENT_MATCH_SUCCESS';
export const ADMIN_FETCH_CLIENT_MATCH_FAILURE =
  'ADMIN_FETCH_CLIENT_MATCH_FAILURE';

export const ADMIN_CREATE_CLIENT = 'ADMIN_CREATE_CLIENT';

export const ADMIN_UPDATE_CLIENT = 'ADMIN_UPDATE_CLIENT';
export const ADMIN_SEARCH_CLIENTS_REQUEST = 'ADMIN_SEARCH_CLIENTS_REQUEST';
export const ADMIN_SEARCH_CLIENTS_SUCCESS = 'ADMIN_SEARCH_CLIENTS_SUCCESS';
export const ADMIN_SEARCH_CLIENTS_FAILURE = 'ADMIN_SEARCH_CLIENTS_FAILURE';
export const ADMIN_FETCH_PROVIDER_CLIENTS_REQUEST =
  'ADMIN_FETCH_PROVIDER_CLIENTS_REQUEST';
export const ADMIN_FETCH_PROVIDER_CLIENTS_SUCCESS =
  'ADMIN_FETCH_PROVIDER_CLIENTS_SUCCESS';
export const ADMIN_FETCH_PROVIDER_CLIENTS_FAILURE =
  'ADMIN_FETCH_PROVIDER_CLIENTS_FAILURE';
export const ADMIN_FETCH_PAGINATED_CLIENTS_REQUEST =
  'ADMIN_FETCH_PAGINATED_CLIENTS_REQUEST';
export const ADMIN_FETCH_PAGINATED_CLIENTS_SUCCESS =
  'ADMIN_FETCH_PAGINATED_CLIENTS_SUCCESS';
export const ADMIN_FETCH_PAGINATED_CLIENTS_FAILURE =
  'ADMIN_FETCH_PAGINATED_CLIENTS_FAILURE';
export const ADMIN_FETCH_PAGINATED_PROVIDERS_REQUEST =
  'ADMIN_FETCH_PAGINATED_PROVIDERS_REQUEST';
export const ADMIN_FETCH_PAGINATED_PROVIDERS_SUCCESS =
  'ADMIN_FETCH_PAGINATED_PROVIDERS_SUCCESS';
export const ADMIN_FETCH_PAGINATED_PROVIDERS_FAILURE =
  'ADMIN_FETCH_PAGINATED_PROVIDERS_FAILURE';
export const ADMIN_SEARCH_PROVIDERS_REQUEST = 'ADMIN_SEARCH_PROVIDERS_REQUEST';
export const ADMIN_SEARCH_PROVIDERS_SUCCESS = 'ADMIN_SEARCH_PROVIDERS_SUCCESS';
export const ADMIN_SEARCH_PROVIDERS_FAILURE = 'ADMIN_SEARCH_PROVIDERS_FAILURE';
export const ADMIN_FETCH_PROVIDER_PAGINATION_INFO =
  'ADMIN_FETCH_PROVIDER_PAGINATION_INFO';
export const ADMIN_FETCH_ANALYTICS = 'ADMIN_FETCH_ANALYTICS';

export const ADMIN_FETCH_APPOINTMENT_REQUEST =
  'ADMIN_FETCH_APPOINTMENT_REQUEST';
export const ADMIN_FETCH_APPOINTMENT_SUCCESS =
  'ADMIN_FETCH_APPOINTMENT_SUCCESS';
export const ADMIN_FETCH_APPOINTMENT_FAILURE =
  'ADMIN_FETCH_APPOINTMENT_FAILURE';
export const ADMIN_FETCH_APPOINTMENTS_REQUEST =
  'ADMIN_FETCH_APPOINTMENTS_REQUEST';
export const ADMIN_FETCH_APPOINTMENTS_SUCCESS =
  'ADMIN_FETCH_APPOINTMENTS_SUCCESS';
export const ADMIN_FETCH_APPOINTMENTS_FAILURE =
  'ADMIN_FETCH_APPOINTMENTS_FAILURE';
export const ADMIN_FETCH_PROVIDER_APPOINTMENTS_REQUEST =
  'ADMIN_FETCH_PROVIDER_APPOINTMENTS_REQUEST';
export const ADMIN_FETCH_PROVIDER_APPOINTMENTS_SUCCESS =
  'ADMIN_FETCH_PROVIDER_APPOINTMENTS_SUCCESS';
export const ADMIN_FETCH_PROVIDER_APPOINTMENTS_FAILURE =
  'ADMIN_FETCH_PROVIDER_APPOINTMENTS_FAILURE';
export const ADMIN_UPDATE_APPOINTMENT_SUCCESS =
  'ADMIN_UPDATE_APPOINTMENT_SUCCESS';
export const ADMIN_FETCH_CURRENT_APPOINTMENTS_REQUEST =
  'ADMIN_FETCH_CURRENT_APPOINTMENTS_REQUEST';
export const ADMIN_FETCH_CURRENT_APPOINTMENTS_SUCCESS =
  'ADMIN_FETCH_CURRENT_APPOINTMENTS_SUCCESS';
export const ADMIN_FETCH_CURRENT_APPOINTMENTS_FAILURE =
  'ADMIN_FETCH_CURRENT_APPOINTMENTS_FAILURE';
export const ADMIN_FETCH_CLIENT_APPOINTMENTS_REQUEST =
  'ADMIN_FETCH_CLIENT_APPOINTMENTS_REQUEST';
export const ADMIN_FETCH_CLIENT_APPOINTMENTS_SUCCESS =
  'ADMIN_FETCH_CLIENT_APPOINTMENTS_SUCCESS';
export const ADMIN_FETCH_CLIENT_APPOINTMENTS_FAILURE =
  'ADMIN_FETCH_CLIENT_APPOINTMENTS_FAILURE';

export const ADMIN_FETCH_CLIENT_PAGINATION_INFO =
  'ADMIN_FETCH_CLIENT_PAGINATION_INFO';
export const ADMIN_FETCH_APPOINTMENT_PAGINATION_INFO =
  'ADMIN_FETCH_APPOINTMENT_PAGINATION_INFO';
export const ADMIN_FETCH_DISCOUNT_CODE_PAGINATION_INFO =
  'ADMIN_FETCH_DISCOUNT_CODE_PAGINATION_INFO';

export const ADMIN_UPDATE_AGE_GROUPS = 'ADMIN_UPDATE_AGE_GROUPS';
export const ADMIN_UPDATE_DEGREES = 'ADMIN_UPDATE_DEGREES';
export const ADMIN_UPDATE_DESIGNATIONS = 'ADMIN_UPDATE_DESIGNATIONS';
export const ADMIN_UPDATE_DISORDERS = 'ADMIN_UPDATE_DISORDERS';
export const ADMIN_UPDATE_LANGUAGES = 'ADMIN_UPDATE_LANGUAGES';
export const ADMIN_UPDATE_RELIGIONS = 'ADMIN_UPDATE_RELIGIONS';
export const ADMIN_UPDATE_PROVIDER_DETAILS = 'ADMIN_UPDATE_PROVIDER_DETAILS';
export const ADMIN_UPDATE_PROVIDER_BIO = 'ADMIN_UPDATE_PROVIDER_BIO';
export const ADMIN_UPDATE_ADDRESS = 'ADMIN_UPDATE_ADDRESS';
export const ADMIN_UPDATE_PHONE_NUMBERS = 'ADMIN_UPDATE_PHONE_NUMBERS';
export const ADMIN_UPDATE_PREFERENCES = 'ADMIN_UPDATE_PREFERENCES';
export const ADMIN_UPDATE_BASE_COSTS = 'ADMIN_UPDATE_BASE_COSTS';
export const ADMIN_UPDATE_REFERRAL_CODE = 'ADMIN_UPDATE_REFERRAL_CODE';
export const ADMIN_FETCH_COUNSELLOR_DOCUMENTS =
  'ADMIN_FETCH_COUNSELLOR_DOCUMENTS';
export const ADMIN_VERIFY_COUNSELLOR_DOCUMENT =
  'ADMIN_VERIFY_COUNSELLOR_DOCUMENT';
export const ADMIN_UPLOAD_COUNSELLOR_DOCUMENT_REQUEST =
  'ADMIN_UPLOAD_COUNSELLOR_DOCUMENT_REQUEST';
export const ADMIN_UPLOAD_COUNSELLOR_DOCUMENT_SUCCESS =
  'ADMIN_UPLOAD_COUNSELLOR_DOCUMENT_SUCCESS';
export const ADMIN_UPLOAD_COUNSELLOR_DOCUMENT_FAILURE =
  'ADMIN_UPLOAD_COUNSELLOR_DOCUMENT_FAILURE';

export const ADMIN_FETCH_DISCOUNT_CODES = 'ADMIN_FETCH_DISCOUNT_CODES';
export const ADMIN_FETCH_DISCOUNT_CODE = 'ADMIN_FETCH_DISCOUNT_CODE';
export const ADMIN_UPDATE_DISCOUNT_CODE = 'ADMIN_UPDATE_DISCOUNT_CODE';
export const ADMIN_FETCH_USER_USED_DISCOUNT = 'ADMIN_FETCH_USER_USED_DISCOUNT';
export const ADMIN_CREATE_DISCOUNT_CODE = 'ADMIN_CREATE_DISCOUNT_CODE';
export const ADMIN_CREATE_DISCOUNT_CODE_FAILURE =
  'ADMIN_CREATE_DISCOUNT_CODE_FAILURE';
export const ADMIN_DELETE_DISCOUNT_CODE = 'ADMIN_DELETE_DISCOUNT_CODE';
export const ADMIN_FETCH_PAGINATED_DISCOUNT_CODES_REQUEST =
  'ADMIN_FETCH_PAGINATED_DISCOUNT_CODES_REQUEST';
export const ADMIN_FETCH_PAGINATED_DISCOUNT_CODES_SUCCESS =
  'ADMIN_FETCH_PAGINATED_DISCOUNT_CODES_SUCCESS';
export const ADMIN_FETCH_PAGINATED_DISCOUNT_CODES_FAILURE =
  'ADMIN_FETCH_PAGINATED_DISCOUNT_CODES_FAILURE';
export const ADMIN_SEARCH_DISCOUNT_CODES_REQUEST =
  'ADMIN_SEARCH_DISCOUNT_CODES_REQUEST';
export const ADMIN_SEARCH_DISCOUNT_CODES_SUCCESS =
  'ADMIN_SEARCH_DISCOUNT_CODES_SUCCESS';
export const ADMIN_SEARCH_DISCOUNT_CODES_FAILURE =
  'ADMIN_SEARCH_DISCOUNT_CODES_FAILURE';

export const ADMIN_FETCH_METRICS_SUCCESS = 'ADMIN_FETCH_METRICS_SUCCESS';

export const ADMIN_FETCH_REPORT_REQUEST = 'ADMIN_FETCH_REPORT_REQUEST';
export const ADMIN_FETCH_REPORT_SUCCESS = 'ADMIN_FETCH_REPORT_SUCCESS';
export const ADMIN_FETCH_REPORT_FAILURE = 'ADMIN_FETCH_REPORT_FAILURE';
export const ADMIN_FETCH_REPORT_PAGINATION_INFO =
  'ADMIN_FETCH_REPORT_PAGINATION_INFO';
export const ADMIN_FETCH_PROVIDER_CLIENT_PAGINATION_INFO =
  'ADMIN_FETCH_PROVIDER_CLIENT_PAGINATION_INFO ';

export const ADMIN_FETCH_AVAILABILITY_SUCCESS =
  'ADMIN_FETCH_AVAILABILITY_SUCCESS';
export const ADMIN_FETCH_AVAILABILITY_BY_ID_SUCCESS =
  'ADMIN_FETCH_AVAILABILITY_BY_ID_SUCCESS';
export const ADMIN_CREATE_AVAILABILITY_SUCCESS =
  'ADMIN_CREATE_AVAILABILITY_SUCCESS';
export const ADMIN_UPDATE_AVAILABILITY_SUCCESS =
  'ADMIN_UPDATE_AVAILABILITY_SUCCESS';
export const ADMIN_DELETE_AVAILABILITY_SUCCESS =
  'ADMIN_DELETE_AVAILABILITY_SUCCESS';
export const ADMIN_COMBINE_AVAILABILITY_SUCCESS =
  'ADMIN_COMBINE_AVAILABILITY_SUCCESS';
export const ADMIN_CANCEL_APPOINTMENT_SUCCESS =
  'ADMIN_CANCEL_APPOINTMENT_SUCCESS';
export const ADMIN_CREATE_APPOINTMENT_SUCCESS =
  'ADMIN_CREATE_APPOINTMENT_SUCCESS';

export const ADMIN_FETCH_SH_INIT = 'ADMIN_FETCH_SH_INIT';
export const ADMIN_FETCH_SH_CLIENT_REQUEST = 'ADMIN_FETCH_SH_CLIENT_REQUEST';
export const ADMIN_FETCH_SH_CLIENT_SUCCESS = 'ADMIN_FETCH_SH_CLIENT_SUCCESS';
export const ADMIN_FETCH_SH_CLIENT_FAILURE = 'ADMIN_FETCH_SH_CLIENT_FAILURE';
export const ADMIN_FETCH_SH_STRIPE_CUSTOMERS_REQUEST =
  'ADMIN_FETCH_SH_STRIPE_CUSTOMERS_REQUEST';
export const ADMIN_FETCH_SH_STRIPE_CUSTOMERS_SUCCESS =
  'ADMIN_FETCH_SH_STRIPE_CUSTOMERS_SUCCESS';
export const ADMIN_FETCH_SH_STRIPE_CUSTOMERS_FAILURE =
  'ADMIN_FETCH_SH_STRIPE_CUSTOMERS_FAILURE';
export const ADMIN_CREATE_STRIPE_CUSTOMERS_REQUEST =
  'ADMIN_CREATE_STRIPE_CUSTOMERS_REQUEST';
export const ADMIN_CREATE_STRIPE_CUSTOMERS_SUCCESS =
  'ADMIN_CREATE_STRIPE_CUSTOMERS_SUCCESS';
export const ADMIN_CREATE_STRIPE_CUSTOMERS_FAILURE =
  'ADMIN_CREATE_STRIPE_CUSTOMERS_FAILURE';

export const ADMIN_MISC_SET_PAYOUT_FILTERS = 'ADMIN_MISC_SET_PAYOUT_FILTERS';
export const ADMIN_MISC_SET_FAILED_PAYMENTS_FILTERS =
  'ADMIN_MISC_SET_FAILED_PAYMENTS_FILTERS';
export const ADMIN_MISC_SET_SESSION_NOTE_FILTERS =
  'ADMIN_MISC_SET_SESSION_NOTE_FILTERS';

export const ADMIN_FETCH_GOVERNING_BODIES = 'ADMIN_FETCH_GOVERNING_BODIES';
export const ADMIN_UPDATE_GOVERNING_BODIES = 'ADMIN_UPDATE_GOVERNING_BODIES';

export const ADMIN_SEARCH_PRACTITIONERS = 'ADMIN_SEARCH_PRACTITIONERS';
export const ADMIN_SEARCH_PRACTITIONERS_REQUEST =
  'ADMIN_SEARCH_PRACTITIONERS_REQUEST';
export const ADMIN_SEARCH_PRACTITIONERS_SUCCESS =
  'ADMIN_SEARCH_PRACTITIONERS_SUCCESS';
export const ADMIN_SEARCH_PRACTITIONERS_FAILURE =
  'ADMIN_SEARCH_PRACTITIONERS_FAILURE';

export const EMAIL_ERROR = 'EMAIL_ERROR';
export const URL_ERROR = 'URL_ERROR';
export const FETCH_ANALYTICS = 'FETCH_ANALYTICS';
export const FETCH_SESSION_STATS = 'FETCH_SESSION_STATS';

// Loading
export const CREATE_PROVIDER_REQUEST = 'CREATE_PROVIDER_REQUEST';
export const ADMIN_FETCH_COMPANIES_SUCCESS = 'ADMIN_FETCH_COMPANIES_SUCCESS';
export const ADMIN_FETCH_COMPANY_SUCCESS = 'ADMIN_FETCH_COMPANY_SUCCESS';
export const ADMIN_CREATE_COMPANY_SUCCESS = 'ADMIN_CREATE_COMPANY_SUCCESS';
export const ADMIN_CREATE_PEOPLE_COMPANY_SUCCESS =
  'ADMIN_CREATE_PEOPLE_COMPANY_SUCCESS';
export const ADMIN_UPDATE_COMPANY_SUCCESS = 'ADMIN_UPDATE_COMPANY_SUCCESS';
export const UPLOAD_COMPANY_USERS = 'UPLOAD_COMPANY_USERS';
export const UPLOAD_TO_TEMP = 'UPLOAD_TO_TEMP';
export const REMOVE_CORPORATE_USERS = 'REMOVE_CORPORATE_USERS';

// stripe_customer
export const FETCH_STRIPE_CUSTOMERS_REQUEST = 'FETCH_STRIPE_CUSTOMERS_REQUEST';
export const FETCH_STRIPE_CUSTOMERS_SUCCESS = 'FETCH_STRIPE_CUSTOMERS_SUCCESS';
export const FETCH_STRIPE_CUSTOMERS_FAILURE = 'FETCH_STRIPE_CUSTOMERS_FAILURE';
export const CREATE_STRIPE_CUSTOMERS_REQUEST =
  'CREATE_STRIPE_CUSTOMERS_REQUEST';
export const CREATE_STRIPE_CUSTOMERS_SUCCESS =
  'CREATE_STRIPE_CUSTOMERS_SUCCESS';
export const CREATE_STRIPE_CUSTOMERS_FAILURE =
  'CREATE_STRIPE_CUSTOMERS_FAILURE';
export const REMOVE_CLIENT_CREDIT_CARD_REQUEST =
  'REMOVE_CLIENT_CREDIT_CARD_REQUEST';
export const REMOVE_CLIENT_CREDIT_CARD_SUCCESS =
  'REMOVE_CLIENT_CREDIT_CARD_SUCCESS';
export const REMOVE_CLIENT_CREDIT_CARD_FAILURE =
  'REMOVE_CLIENT_CREDIT_CARD_FAILURE';

// state for schedule helper
export const FETCH_SH_INIT = 'FETCH_SH_INIT';
export const FETCH_SH_CLIENT_REQUEST = 'FETCH_SCHEDULE_CLIENT_REQUEST';
export const FETCH_SH_CLIENT_SUCCESS = 'FETCH_SCHEDULE_CLIENT_SUCCESS';
export const FETCH_SH_CLIENT_FAILURE = 'FETCH_SCHEDULE_CLIENT_FAILURE';
export const FETCH_SH_STRIPE_CUSTOMERS_REQUEST =
  'FETCH_SH_STRIPE_CUSTOMERS_REQUEST';
export const FETCH_SH_STRIPE_CUSTOMERS_SUCCESS =
  'FETCH_SH_STRIPE_CUSTOMERS_SUCCESS';
export const FETCH_SH_STRIPE_CUSTOMERS_FAILURE =
  'FETCH_SH_STRIPE_CUSTOMERS_FAILURE';

// life coaching
export const FETCH_USER_LIFE_COACHING = 'FETCH_USER_LIFE_COACHING';
export const CREATE_USER_LIFE_COACHING = 'CREATE_USER_LIFE_COACHING';
export const DELETE_USER_LIFE_COACHING = 'DELETE_USER_LIFE_COACHING';

// supervision
export const CREATE_SUPERVISION_SURVEY = 'CREATE_SUPERVISION_SURVEY';
export const SUPERVISION_SURVEY_COMPLETE = 'SUPERVISION_SURVEY_COMPLETE';

// global snackbar message
export const OPEN_GLOBAL_SNACKBAR = 'OPEN_GLOBAL_SNACKBAR';
export const CLOSE_GLOBAL_SNACKBAR = 'CLOSE_GLOBAL_SNACKBAR';

export const TWO_FACTOR = 'TWO_FACTOR';
export const TWO_FACTOR_ERROR = 'TWO_FACTOR_ERROR';

export const ADMIN_SEARCH_COMPANIES_SUCCESS = 'ADMIN_SEARCH_COMPANIES_SUCCESS';
export const ADMIN_SEARCH_COMPANIES_REQUEST = 'ADMIN_SEARCH_COMPANIES_REQUEST';
export const ADMIN_SEARCH_COMPANIES_FAILIURE =
  'ADMIN_SEARCH_COMPANIES_FAILIURE';

export const ADMIN_EXPORT_LIST_REQUEST = 'ADMIN_EXPORT_LIST_REQUEST';
export const ADMIN_EXPORT_LIST_SUCCESS = 'ADMIN_EXPORT_LIST_SUCCESS';
export const ADMIN_EXPORT_LIST_FAILURE = 'ADMIN_EXPORT_LIST_FAILURE';

export const ADMIN_MATCH_PROVIDER_TO_CLIENT_REQUEST =
  'ADMIN_MATCH_PROVIDER_TO_CLIENT_REQUEST';
export const ADMIN_MATCH_PROVIDER_TO_CLIENT_SUCCESS =
  'ADMIN_MATCH_PROVIDER_TO_CLIENT_SUCCESS';
export const ADMIN_MATCH_PROVIDER_TO_CLIENT_FAILURE =
  'ADMIN_MATCH_PROVIDER_TO_CLIENT_FAILURE';

export const ADMIN_UNMATCH_PROVIDER_FROM_CLIENT_REQUEST =
  'ADMIN_UNMATCH_PROVIDER_FROM_CLIENT_REQUEST';
export const ADMIN_UNMATCH_PROVIDER_FROM_CLIENT_SUCCESS =
  'ADMIN_UNMATCH_PROVIDER_FROM_CLIENT_SUCCESS';
export const ADMIN_UNMATCH_PROVIDER_FROM_CLIENT_FAILURE =
  'ADMIN_UNMATCH_PROVIDER_FROM_CLIENT_FAILURE';

export const ADMIN_FETCH_COMPANY_LIST_REQUEST =
  'ADMIN_FETCH_COMPANY_LIST_REQUEST';
export const ADMIN_FETCH_COMPANY_LIST_SUCCESS =
  'ADMIN_FETCH_COMPANY_LIST_SUCCESS';
export const ADMIN_FETCH_COMPANY_LIST_FAILURE =
  'ADMIN_FETCH_COMPANY_LIST_FAILURE';

export const ADMIN_UPDATE_GOVERNING_BODIES_SUCCESS =
  'ADMIN_UPDATE_GOVERNING_BODIES_SUCCESS';

export const ADMIN_DELETE_GOVERNING_BODIES_SUCCESS =
  'ADMIN_DELETE_GOVERNING_BODIES_SUCCESS';

export const FETCH_PRACTICE_PREFERENCES_REQUEST =
  'FETCH_PRACTICE_PREFERENCES_REQUEST';
export const FETCH_PRACTICE_PREFERENCES_SUCCESS =
  'FETCH_PRACTICE_PREFERENCES_SUCCESS';
export const FETCH_PRACTICE_PREFERENCES_FAILURE =
  'FETCH_PRACTICE_PREFERENCES_FAILURE';

export const SET_APP_LOCALE = 'SET_APP_LOCALE';

// session status
export const FETCH_SESSION_STATUS_APPOINTMENT_REQUEST =
  'FETCH_SESSION_STATUS_APPOINTMENT_REQUEST';
export const FETCH_SESSION_STATUS_APPOINTMENT_SUCCESS =
  'FETCH_SESSION_STATUS_APPOINTMENT_SUCCESS';
export const FETCH_SESSION_STATUS_APPOINTMENT_FAILURE =
  'FETCH_SESSION_STATUS_APPOINTMENT_FAILURE';

export const CREATE_SESSION_STATUS_CANCELLATION_REQUEST =
  'CREATE_SESSION_STATUS_CANCELLATION_REQUEST';
export const CREATE_SESSION_STATUS_CANCELLATION_SUCCESS =
  'CREATE_SESSION_STATUS_CANCELLATION_SUCCESS';
export const CREATE_SESSION_STATUS_CANCELLATION_FAILURE =
  'CREATE_SESSION_STATUS_CANCELLATION_FAILURE';

export const UPDATE_SESSION_STATUS_COMPLETE_REQUEST =
  'UPDATE_SESSION_STATUS_COMPLETE_REQUEST';
export const UPDATE_SESSION_STATUS_COMPLETE_SUCCESS =
  'UPDATE_SESSION_STATUS_COMPLETE_SUCCESS';
export const UPDATE_SESSION_STATUS_COMPLETE_FAILURE =
  'UPDATE_SESSION_STATUS_COMPLETE_FAILURE';

export const FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_REQUEST =
  'FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_REQUEST';
export const FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_SUCCESS =
  'FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_SUCCESS';
export const FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_FAILURE =
  'FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_FAILURE';

export const UPDATE_SESSION_STATUS_ADMIN_REQUEST =
  'UPDATE_SESSION_STATUS_ADMIN_REQUEST';
export const UPDATE_SESSION_STATUS_ADMIN_SUCCESS =
  'UPDATE_SESSION_STATUS_ADMIN_SUCCESS';
export const UPDATE_SESSION_STATUS_ADMIN_FAILURE =
  'UPDATE_SESSION_STATUS_ADMIN_FAILURE';

export const CREATE_SESSION_STATUS_ADMIN_CANCELLATION_REQUEST =
  'CREATE_SESSION_STATUS_ADMIN_CANCELLATION_REQUEST';
export const CREATE_SESSION_STATUS_ADMIN_CANCELLATION_SUCCESS =
  'CREATE_SESSION_STATUS_ADMIN_CANCELLATION_SUCCESS';
export const CREATE_SESSION_STATUS_ADMIN_CANCELLATION_FAILURE =
  'CREATE_SESSION_STATUS_ADMIN_CANCELLATION_FAILURE';
