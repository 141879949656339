/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import _ from 'lodash';
import moment from 'moment-timezone';
import { updateUser } from 'actions/user';
import { setAppLocale } from 'actions/misc';
import English from '../lang/en.json';
import French from '../lang/fr.json';

export const LanguageContext = React.createContext({
  locale: 'en',
  setLocale: () => {},
});

class LanguageProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locale: props.appLocale,
      setLocale: this.setLocale,
    };

    moment.locale(props.appLocale);
  }

  setLocale = newLocale => {
    const { user } = this.props;

    moment.locale(newLocale);
    localStorage.setItem('locale', newLocale);
    this.setState({ locale: newLocale });
    this.props.setAppLocale(newLocale);

    if (!_.isEmpty(user)) {
      this.props.updateUser({ locale: newLocale }, () =>
        window.location.reload(),
      );
    }
  };

  render() {
    const { appLocale } = this.props;

    return (
      <LanguageContext.Provider value={this.state}>
        <IntlProvider
          locale={appLocale}
          messages={appLocale === 'en' ? English : French}
          textComponent={React.Fragment}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        >
          {React.Children.only(this.props.children)}
        </IntlProvider>
      </LanguageContext.Provider>
    );
  }
}

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
  updateUser: PropTypes.func.isRequired,
  setAppLocale: PropTypes.func.isRequired,
  appLocale: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {
    user,
    misc: { appLocale },
  } = state;

  return { user, appLocale };
}

export default connect(mapStateToProps, { updateUser, setAppLocale })(
  LanguageProvider,
);
