import { AUTH_ERROR } from './types';

export function headers() {
  return {
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  };
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  };
}
